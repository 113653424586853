import { atom, atomFamily, selector, selectorFamily, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {Action, Category, CategoryMeta, SubCategory} from "@hexlabsio/dasha-categories-sdk";
import { useHistory, useLocation } from "react-router-dom";
import {useCallback} from "react";
import {jwtToken} from "../user/auth-state";
import {categorySdk} from "../sdks";
import {layerCollectionState} from "../layers/layer-state";
// eslint-disable-next-line import/no-cycle
import {markerCollectionState} from "../markers/marker-state";
// eslint-disable-next-line import/no-cycle
import {overlayCollectionState} from "../custom-state";
import { openSidebar, resetter, SearchArea } from "../shared-state";



const england: Category = {
    title: 'England',
    identifier: 'All',
    centre: { latitude: 52.86548044589023, longitude: -0.8851664231381411 },
    zoom: 7,
    categories: [],
    initialLayerIds: [],
    regionImage: undefined
}

const userCategoriesSelector = selector<CategoryMeta[]>({
    key: 'userCategoriesSelector',
    get: async ({get}) => {
        const jwt = get(jwtToken)
        if(jwt) {
            const response = await categorySdk(jwt).getCategories();
            if(response.statusCode === 200) {
                return [{identifier: england.identifier, title: england.title}, ...response.result.member];
            }
        }
        return [];
    }
});

export const userCategories = atom<CategoryMeta[]>({key: 'userCategories', default: userCategoriesSelector})


function sortSubCategory(subCategory: SubCategory): SubCategory {
    return { ...subCategory, categories: subCategory.categories.map(it => sortSubCategory(it)).sort((a, b) => a.title.localeCompare(b.title))}
}

function sortCategories(category: Category): Category {
    return { ...category, categories: category.categories.map(it => sortSubCategory(it))}
}

const categorySelector = selectorFamily<Category | undefined, string>({
    key: 'categorySelector',
    get: (category: string) => async ({get}) => {
        if(category === 'All') {
            return england
        }
        const jwt = get(jwtToken)
        if(jwt) {
            const response = await categorySdk(jwt).getCategory({identifier: category});
            if(response.statusCode === 200) {
                return sortCategories(response.result)
            }
        }
        return undefined;
    }
});

export const categoryState = atomFamily({key: 'Categories', default: categorySelector});

export interface ButtonInfo {identifier: string; category: string; selected: boolean; loading: boolean; actions: Action[], count: number; error?: string; parents: string[] }

function asButtons(parentId: string, category: SubCategory, parents: string[]): ButtonInfo[] {
    if(category.actions?.length !== 0) {
        return [{actions: category.actions ?? [], identifier: category.identifier, category: parentId, loading: false, selected: false, count: 0, parents }];
    }
    return category.categories.flatMap(child => asButtons(parentId, child, [...parents, child.identifier]));
}

const buttonSelector = selectorFamily<ButtonInfo[], {category: string}>({
    key: 'buttonSelector',
    get: ({category}) => ({get}) => {
        const c = get(categoryState(category));
        return c?.categories?.flatMap(child => asButtons(c?.identifier, child, [child.identifier])) ?? [];
    }
});
export const buttonsState = atomFamily({key: 'buttonsState', default: buttonSelector});

export function useParentSelected(category: string, identifier: string): boolean {
    const buttons = useRecoilValue(buttonsState({category}));
    return [...new Set(buttons.flatMap(it => it.selected ? it.parents : []))].includes(identifier);
}

const singleButtonSelector = selectorFamily<ButtonInfo, {category: string, identifier: string}>({
    key: 'singleButtonSelector',
    get: ({category, identifier}) => ({get}) => {
        const buttons = get(buttonsState({category}));
        return buttons.find(it => it.identifier === identifier) ?? {actions: [], identifier, loading: false, selected: false, category, count: 0, parents: []};
    }
});

export const buttonState = atomFamily({key: 'buttonState', default: singleButtonSelector});
export const collectionHighlight = atom<[ButtonInfo, string] | undefined>({key: 'collectionHighlight', default: undefined});
export function shownCollections(): Array<SearchArea & {id: string}> {
    const selected = new URLSearchParams(window.location.search).getAll('selected');
    return selected.map(it => {
        try {return JSON.parse(decodeURIComponent(it))} catch(e){
            return undefined;
        }
    }).filter(it => !!it && it.id);
}
export function useShownCollections(): Array<SearchArea & {id: string}> {
    const location = useLocation();
    const selected = new URLSearchParams(location.search).getAll('selected');
    return selected.map(it => {
        try {return JSON.parse(decodeURIComponent(it))} catch(e){
            return undefined;
        }
    }).filter(it => !!it && it.id);
}
export function useUpdateShownCollections() {
    const history = useHistory();
    return (fn: (old: Array<SearchArea & {id: string}>) => Array<SearchArea & {id: string}>) => {
        const collections = fn(shownCollections());
        const uriSearch = new URLSearchParams(window.location.search);
        uriSearch.delete('selected');
        collections.forEach(it => uriSearch.append('selected', encodeURIComponent(JSON.stringify(it))))
        history.replace({search: uriSearch.toString()})
    }
}

export const selectedCategorySelector = selector<CategoryMeta | undefined>({key: 'selectedCategorySelector', get: ({get}) => {
        const categories = get(userCategories);
        return categories[0]
    }});
export const selectedCategory = atom<CategoryMeta | undefined>({key: 'selectedCategory', default: selectedCategorySelector})


export function useSelectedCategory() {
    const history = useHistory();
    const categories = useRecoilValue(userCategories);
    if(categories.length === 0) {
        return {
            identifier: 'unknown',
            title: 'unknown'
        }
    }
    const selected = new URLSearchParams(history.location.search).get('category');
    const match = categories.find(it => it.identifier === selected);
    if (selected && match) {
        return match;
    }
    // if(categories.length > 0) {
    //     const uriSearch = new URLSearchParams(history.location.search);
    //
    //     uriSearch.set('category', categories[0].identifier);
    //     history.location.search = uriSearch.toString();
    //     history.replace(history.location)
    //     return categories[0];
    // }
    return {identifier: 'All', title: 'England'};
}


export function useResetState() {
    const setResetState = useSetRecoilState(resetter);
    const [, setSidebarOpen] = useRecoilState(openSidebar);
    const category = useSelectedCategory()!;
    const buttons = useSetRecoilState(buttonsState({category: category.identifier}));
    const shown = useUpdateShownCollections();
    const setLayers = useSetRecoilState(layerCollectionState({category: category.identifier}));
    const setMarkers = useSetRecoilState(markerCollectionState({category: category.identifier}));
    const setOverlays = useSetRecoilState(overlayCollectionState({category: category.identifier}));
    return useCallback( () => {
        buttons(old => old.map(it => ({...it, selected: false, loading: false, count: 0, error: undefined})));
        shown(() => []);
        setLayers(old => old.filter(it => it.permanent));
        setMarkers([]);
        setOverlays([]);
        setResetState(old => old + 1);
        setSidebarOpen(undefined);
    }, [category, shown])
}

export function useSelectCategory() {
    const reset = useResetState();
    const history = useHistory();
    return (category: string) => {
        reset();
        setTimeout(() => {
            const uriSearch = new URLSearchParams();
            uriSearch.set('category', category);
            history.location.search = uriSearch.toString();
            history.push(history.location)
        }, 100)
    }
}
