import { SubCategory } from '@hexlabsio/dasha-categories-sdk';
import { atom } from "recoil";
import {Coordinate} from "@hexlabsio/dasha-markers-sdk";
import {GeoJSONFeature} from "@hexlabsio/dasha-layers-sdk";
import {useHistory} from "react-router-dom";

export const openSidebar = atom<SubCategory | undefined>({ key: 'sidebarOpen', default: undefined });
export const showTraffic = atom<boolean>({key: 'showTrafficState', default: false});
export const mapCenter = atom<[number, number]>({
    key: 'mapCenter',
    default: [51.51302271808127, -0.15288864566795388]
});

export const mapBounds = atom<{min: [number, number], max: [number, number]}>({
    key: 'mapBounds',
    default: { min: [51.51302271808127, -0.15288864566795388], max: [51.51302271808127, -0.15288864566795388]}
});

export const flyToLocation = atom<[Coordinate] | [number, number, number] | [Coordinate, Coordinate] | undefined>({
    key: 'flyToLocation',
    default: undefined
});
export interface LatLongBox {
    start: [number, number];
    end: [number, number];
    min: [number, number];
    max: [number, number]
}
export const searchBounds = atom<LatLongBox | undefined>({
    key: 'searchBounds',
    default: undefined
});

export function useSearchBounds() {
    const history = useHistory();
    return JSON.parse(new URLSearchParams(history.location.search).get('searchBox') ?? '{}');
}
export const searchRadius = atom<number>({key: 'searchRadius', default: 0});

export function findBounds(coordinates: Coordinate[]): [Coordinate, Coordinate] | [Coordinate] | undefined {
    const coords = coordinates.filter(it => it.latitude > 45 && it.latitude < 60 && it.longitude > -14 && it.longitude < 5);
    if (coords.length > 0) {
        const bounds = coords.reduce(([[ml, mln], [xl, xln]], next) => [
            [next.latitude < ml ? next.latitude : ml, next.longitude < mln ? next.longitude : mln],
            [next.latitude > xl ? next.latitude : xl, next.longitude > xln ? next.longitude : xln]
        ] as [[number, number], [number, number]], [[coords[0].latitude, coords[0].longitude], [coords[0].latitude, coords[0].longitude]] as [[number, number], [number, number]]);
        if (bounds[0][0] === bounds[1][0] && bounds[0][1] === bounds[1][1]) return [{latitude: bounds[0][0], longitude: bounds[0][1]}];
        return [{latitude: bounds[0][0], longitude: bounds[0][1]}, {latitude: bounds[1][0], longitude: bounds[1][1]}];
    }
    return undefined;
}

export function findNumberBounds(coordinates: [number, number, ...number[]][]): Coordinate[] | undefined {
    return findBounds(coordinates.map(([longitude, latitude]) => ({latitude, longitude})));
}

function boundsForGeometry(geometry: GeoJSONFeature['geometry']): Coordinate[] | undefined {
    switch(geometry?.type) {
        case 'Polygon': return findNumberBounds(geometry.coordinates.flat());
        case 'LineString': return findNumberBounds(geometry.coordinates);
        case 'GeometryCollection': return findBounds(geometry.geometries.flatMap(it => boundsForGeometry(it) ?? []));
        case 'MultiPolygon': return findNumberBounds(geometry.coordinates.flatMap(it => it.flat()));
        case 'MultiLineString': return findNumberBounds(geometry.coordinates.flat());
        case 'Point': return [{latitude: geometry!.coordinates[1], longitude: geometry!.coordinates[0]}]
        case 'MultiPoint': return findNumberBounds(geometry.coordinates);
        default: return undefined;
    }
}

export function findFeatureBounds(features: GeoJSONFeature[]): [Coordinate] | [Coordinate, Coordinate] | undefined {
    return features.reduce((bounds, feature) => findBounds([...(boundsForGeometry(feature.geometry) ?? []), ...(bounds ?? [])]), undefined as Coordinate[] | undefined) as any;
}

export const resetter = atom({key: 'resetterState', default: 0});

export type CollectionResult = CollectionFailedResult | CollectionSuccessResult;
export interface CollectionFailedResult {
    reason: 'ERROR' | 'NONE_FOUND' | 'NOT_COLLECTION'
}
export interface SearchArea {
    lt?: string;
    ln?: string;
    r?: string;
    mlt?: string;
    mln?: string;
    xlt?: string;
    xln?: string;
}
export interface CollectionSuccessResult {
    total: number;
    searchedArea?: SearchArea
}
