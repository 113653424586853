import {useRecoilState, useRecoilValue} from "recoil";
import {Icon} from "leaflet";
import {Marker, Popup} from "react-leaflet";
import {Box, CircularProgress} from "@mui/material";
import React from "react";
import {collectionHighlight, shownCollections} from "../../hooks/category/category-state";
import {markerCollectionState} from "../../hooks/markers/marker-state";
import useIcons from "../../hooks/use-icons";
import Properties from "./properties";
import {MarkerPopup} from "./popup";

function Markers({category}: {category: string}): JSX.Element {
    const {dataUrlFrom} = useIcons();
    const highlighted = useRecoilValue(collectionHighlight);
    const [markers] = useRecoilState(markerCollectionState({category}));
    return (
        <>
            {markers.filter(buttonResponse => shownCollections().some(it => it.id === buttonResponse.buttonIdentifier))
                .flatMap(buttonResponse => buttonResponse.markers.map(collection => {
                    const icon = (collection.icon && collection.icon.type !== 'FONT') ?  new Icon({ iconUrl: dataUrlFrom(collection.icon as any), ...(collection.icon.imageType.toUpperCase() === 'SVG' ? {iconSize: [26 ,26]} : {}) }) : undefined;
                    const isHighlighted = !highlighted || highlighted[1] === buttonResponse.buttonIdentifier;
                    return <>{ collection.member.filter(marker => marker.coordinate.latitude && marker.coordinate.longitude).map(marker => (
                        <Marker key={marker.identifier} {...(icon && {icon})} position={[marker.coordinate.latitude, marker.coordinate.longitude]} opacity={isHighlighted ? 1 : 0.2}>
                            <Popup autoClose={false} minWidth={300}>
                                <Box display="flex" maxHeight="500px" overflow="auto">
                                    <React.Suspense fallback={<Box height="300px" width="300px" textAlign="center" display="flex" alignItems="center" justifyContent="center"><CircularProgress/></Box>}>
                                        {marker?.markerProperties && <Properties title={marker.title} properties={marker.markerProperties} data={undefined} category={category}/> || <MarkerPopup marker={marker} category={category} collection={collection.identifier}/>}
                                    </React.Suspense>
                                </Box>
                            </Popup>
                        </Marker>
                    ))
                    }</>
                }))}
        </>
    )
}

export default Markers;
